import { api } from '@/boot/api'
import {
  SET_PARTICIPATIONS,
  SET_PARTICIPATION,
  SET_PREFERRED_TRADE_FAIRS,
  SET_NOTED_TRADE_FAIR,
  UNSET_NOTED_TRADE_FAIR,
  SET_PARTICIPATION_PREVIEW_MODE,
  SET_PARTICIPATION_PREVIEW_LANGUAGE,
  SET_PARTICIPATION_PREVIEW_PRICE,
  SET_PARTICIPATION_STEPS_ERRORED, SET_PARTICIPATION_COST
} from '@/store/mutation-types'
import { makeDefaultGetters } from '@/utilities/helpers/store'
import {PRICE_TYPES} from "@/enums/priceTypes";
import {BOOKING_TYPES} from "@/enums/bookingType";
import {$axios} from "@/boot/interceptor";

const defaultState = () => ({
  participations: [],
  participation: {},
  notedTradefairsIds: [],
  preferredTradeFairs: [],
  isPreviewMode: false,
  participationFormLanguagePreview: 'de',
  participationFormPricePreview: PRICE_TYPES.PRICE_SPONSORED,
  stepsError: [],
  cost: {
    total: 0,
    lines: {
      package: null,
      space: null,
      fees: null,
      services: null,
      coExhibitorFee: null,
      coExhibitorExternalFee: null,
      coExhibitorExternalFee2: null
    }
  },
})

const state = defaultState()

const actions = {
  async setParticipationFormLanguagePreview({commit}, lang) {
    commit(SET_PARTICIPATION_PREVIEW_LANGUAGE, lang)
  },

  async setParticipationFormPricePreview({commit, dispatch}, price) {
    commit(SET_PARTICIPATION_PREVIEW_PRICE, price)

    dispatch('fetchParticipationCost')
  },

  async setPreviewMode({ commit }, payload) {
    commit(SET_PARTICIPATION_PREVIEW_MODE, payload)
  },

  // eslint-disable-next-line no-empty-pattern
  async reprocessParticipation({ }, payload) {
    try {
      await $axios.post(`/api/participations/${payload.id}/reprocess`, {
        denied_note: payload.deniedNote
      })

      return true
    } catch (error) {
      console.error('API error (reprocessing participation): ', error)
      return false
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async approveParticipation({  }, payload) {
    try {
      await $axios.post(`/api/participations/${payload}/approve`)

      return true
    } catch (error) {
      console.error('API error (approve participation): ', error)
      return false
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async cancelParticipation({  }, payload) {
    try {
      await $axios.post(`/api/participations/${payload.id}/cancel`, {
        denied_note: payload.deniedNote
      })

      return true
    } catch (error) {
      console.error('API error (approve participation): ', error)
      return false
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async rejectParticipation({  }, payload) {
    try {
      await $axios.post(`/api/participations/${payload.id}/reject`, {
        denied_note: payload.deniedNote
      })

      return true
    } catch (error) {
      console.error('API error (approve participation): ', error)
      return false
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async waitingParticipation({  }, payload) {
    try {
      await $axios.post(`/api/participations/${payload.id}/waiting`)

      return true
    } catch (error) {
      console.error('API error (waiting participation): ', error)
      return false
    }
  },

  async fetchParticipation({ commit },  { id }) {
    try {
      const participation = await $axios.get(`/api/participations/${id}`, {
        params: {
          'languages': '*',
          'include': [
            'cancelledByUser',
            'rejectedByUser',
            'approvedByUser',
            'coexhibitors',
            'company',
            'tradeFairSpaces',
            'tradeFairPackages',
            'areas',
            'tradeFairServices',
            'goods',
            'contacts',
          ],
        },
      })

      commit(SET_PARTICIPATION, participation.data.data)
      return true
    } catch (error) {
      console.error('API error (fetch participation): ', error)
      return false
    }
  },

  async fetchParticipationByTradeFair({ commit }, { fair }) {
    try {
      const participation = await $axios.get(`/api/trade-fairs/${fair.id}/participation`, {
        params: {
          'languages': '*',
          'include': [
            'cancelledByUser',
            'rejectedByUser',
            'approvedByUser',
            'coexhibitors',
            'company',
            'tradeFairSpaces',
            'tradeFairPackages',
            'areas',
            'tradeFairServices',
            'goods',
            'contacts',
          ],
        },
      })

      if (participation) {
        commit(SET_PARTICIPATION, participation.data.data)
      }

      return true
    } catch (error) {
      console.error('API error (fetch participation by trade fair): ', error)
      return false
    }
  },

  async createParticipation({ commit, state, dispatch }, { fair, data }) {
    try {
      if (!state.isPreviewMode) {
        let result = await $axios.post(`/api/trade-fairs/${fair.id}/participations`, data)

        await dispatch('fetchParticipation', { id: result.data.data.id })
      } else {
        commit(SET_PARTICIPATION, { ...state.participation, attributes: { ...state.participation.attributes, ...data } })
      }

      return true
    } catch (error) {
      console.error('API error (store participation): ', error)
      return false
    }
  },

  async updateParticipation({ commit, dispatch }, { fair, participation, data }) {
    try {
      if (!state.isPreviewMode) {
        await $axios.patch(`/api/trade-fairs/${fair.id}/participations/${participation.id}`, data)

        await dispatch('fetchParticipation', { id: participation.id })
      } else {
        commit(SET_PARTICIPATION, { ...state.participation, attributes: { ...state.participation.attributes, ...data } })
      }

      return true
    } catch (error) {
      console.error('API error (update participation): ', error)
      return false
    }
  },

  async submitParticipation({ state, dispatch }, { participation }) {
    try {
      if (!state.isPreviewMode) {
        await $axios.post(`/api/participations/${participation.id}/submit`)

        dispatch('fetchParticipation', { id: participation.id })
      }

      return true
    } catch (error) {
      console.error('API error (submit participation): ', error)
      return false
    }
  },

  async attachArea({ commit, state, dispatch }, { participation, area }) {
    try {
      if (!state.isPreviewMode) {
        await $axios.post(`/api/participations/${participation.id}/area`, {
          tradefair_exhibitionarea_id: area.id
        })

        dispatch('fetchParticipation', { id: participation.id })
      } else {
        commit(SET_PARTICIPATION, { ...state.participation, relationships: { ...state.participation.relationships, area: area } })
      }

      return true
    } catch (error) {
      console.error('API error (attach area to participation): ', error)
      return false
    }
  },

  async attachPackage({ commit, state, dispatch }, { participation, packag }) {
    try {
      if (!state.isPreviewMode) {
        await $axios.post(`/api/participations/${participation.id}/package`, { tradefair_package_id: packag.id },)
        dispatch('fetchParticipation', { id: participation.id })
      } else {
        commit(SET_PARTICIPATION, { ...state.participation, relationships: { ...state.participation.relationships, package: packag, spaces: null } })

        if (!packag.attributes.allow_subexhibitor) {
          for (let coexhibitor of participation.relationships.coexhibitors) {
            dispatch('deleteCoExhibitor', { participation, coexhibitor})
          }
        }
      }

      return true
    } catch (error) {
      console.error('API error (attach package to participation): ', error)
      return false
    }
  },

  async createSpace({ commit, state, dispatch }, { participation, spaces }) {
    try {
      if (!state.isPreviewMode) {
        await $axios.post(`/api/participations/${participation.id}/spaces`, { spaces })

        dispatch('fetchParticipation', { id: participation.id })
      } else {
        commit(SET_PARTICIPATION, { ...state.participation, relationships: { ...state.participation.relationships, spaces: spaces, package: null } })
      }

      return true
    } catch (error) {
      console.error('API error (create participation space): ', error)
      return false
    }
  },

  async applyChanges({ state, commit, dispatch }, { participationId }) {
    try {
      const changes = state.changes;
  
      await $axios.post(`/api/participations/${participationId}/contacts/all`, changes);
  
      await dispatch('fetchParticipation', { id: participationId });
  
      commit('RESET_CHANGES');
  
      this.$snackbar(this.$t('common.successApiMessage'));
    } catch (error) {
      console.error('API error (applyChanges):', error);
  
      if (error?.response?.status === 422) {
        this.setServerErrors(error?.response?.data?.errors ?? {});
      } else {
        this.$snackbar(this.$t('common.errorApiMessage'));
      }
    }
  },
  

  async createCoExhibitor({ commit, state, dispatch }, { participation, coexhibitor }) {
    if (!state.isPreviewMode) {
      await $axios.post(`/api/participations/${participation.id}/coexhibitors`, coexhibitor)

      dispatch('fetchParticipation', { id: participation.id })
    } else {
      let coexhibitors = state.participation?.relationships?.coexhibitors ?? []
      coexhibitor.id = (coexhibitors[coexhibitors.length - 1]?.id ?? 1) + 1
      coexhibitors.push({ id: coexhibitor.id, attributes: coexhibitor })

      commit(SET_PARTICIPATION, { ...state.participation, relationships: { ...state.participation.relationships, coexhibitors: coexhibitors } })
    }
  },

  async updateCoExhibitor({ commit, state, dispatch }, { participation, coexhibitor }) {
    if (!state.isPreviewMode) {
      await $axios.patch(`/api/participations/${participation.id}/coexhibitors/${coexhibitor.id}`, coexhibitor)

      dispatch('fetchParticipation', { id: participation.id })
    } else {
      let coexhibitors = state.participation?.relationships?.coexhibitors ?? []
      let position =  coexhibitors.findIndex((el) => el.id === coexhibitor.id)
      coexhibitors[position] = { id: coexhibitor.id, attributes: coexhibitor }

      commit(SET_PARTICIPATION, { ...state.participation, relationships: { ...state.participation.relationships, coexhibitors: coexhibitors } })
    }

    return true
  },

  async deleteCoExhibitor({ commit, state, dispatch }, { participation, coexhibitor }) {
    try {
      if (!state.isPreviewMode) {
        await $axios.delete(`/api/participations/${participation.id}/coexhibitors/${coexhibitor.id}`, coexhibitor)

        dispatch('fetchParticipation', { id: participation.id })
      } else {
        let coexhibitors = state.participation?.relationships?.coexhibitors ?? []
        coexhibitors =  coexhibitors.filter((el) => el.id !== coexhibitor.id)

        commit(SET_PARTICIPATION, { ...state.participation, relationships: { ...state.participation.relationships, coexhibitors: coexhibitors } })
      }

      return true
    } catch (error) {
      console.error('API error (delete participation co-exhibitor): ', error)
      return false
    }
  },

  async createGoodsItem({ commit, state, dispatch }, { participation, good }) {
    try {
      if (!state.isPreviewMode) {
        await $axios.post(`/api/participations/${participation.id}/goods`, good)

        dispatch('fetchParticipation', { id: participation.id })
      } else {
        let goods = state.participation?.relationships?.goods ?? []
        good.id = (goods[goods.length - 1]?.id ?? 1) + 1
        goods.push({ id: good.id, attributes: good })

        commit(SET_PARTICIPATION, { ...state.participation, relationships: { ...state.participation.relationships, goods: goods } })
      }

      return true
    } catch (error) {
      console.error('API error (create participation goods): ', error)
      return false
    }
  },

  async updateGoodsItem({ commit, state, dispatch }, { participation, good }) {
    try {
      if (!state.isPreviewMode) {
        await $axios.patch(`/api/participations/${participation.id}/goods/${good.id}`, good)

        dispatch('fetchParticipation', { id: participation.id })
      } else {
        let goods = state.participation?.relationships?.goods ?? []
        let position =  goods.findIndex((el) => el.id === good.id)
        goods[position] = { id: good.id, attributes: good }

        commit(SET_PARTICIPATION, { ...state.participation, relationships: { ...state.participation.relationships, goods: goods } })
      }

      return true
    } catch (error) {
      console.error('API error (update participation goods): ', error)
      return false
    }
  },

  async deleteGoodsItem({ commit, state, dispatch }, { participation, good }) {
    try {
      if (!state.isPreviewMode) {
        await $axios.delete(`/api/participations/${participation.id}/goods/${good.id}`)

        dispatch('fetchParticipation', { id: participation.id })
      } else {
        let goods = state.participation?.relationships?.goods ?? []
        goods =  goods.filter((el) => el.id !== good.id)

        commit(SET_PARTICIPATION, { ...state.participation, relationships: { ...state.participation.relationships, goods: goods } })
      }

      return true
    } catch (error) {
      console.error('API error (delete participation goods): ', error)
      return false
    }
  },

  async attachService({ commit, state, dispatch }, { participation, service, data }) {
    try {
      if (!state.isPreviewMode) {
        await $axios.post(`/api/participations/${participation.id}/services`, {
          ...data,
          tradefair_service_id: service.id
        })

        dispatch('fetchParticipation', { id: participation.id })
      } else {
        let services = state.participation?.relationships?.services ?? []
        data.id = (services[services.length - 1]?.id ?? 1) + 1
        data.tradefair_service_id = service.id
        services.push({ id: data.id, attributes: data })

        commit(SET_PARTICIPATION, { ...state.participation, relationships: { ...state.participation.relationships, services: services } })
      }

      return true
    } catch (error) {
      console.error('API error (attach participation service): ', error)
      return false
    }
  },

  async updateService({ commit, state, dispatch }, { participation, service, assigned, data }) {
    try {
      if (!state.isPreviewMode) {
        await $axios.patch(`/api/participations/${participation.id}/services/${assigned.id}`, {
          ...data,
          tradefair_service_id: service.id
        })

        dispatch('fetchParticipation', { id: participation.id })
      } else {
        let services = state.participation?.relationships?.services ?? []
        let position = services.findIndex((el) => el.id === assigned.id)
        data.id = assigned.id
        data.tradefair_service_id = service.id
        services[position] = { id: data.id, attributes: data }

        commit(SET_PARTICIPATION, { ...state.participation, relationships: { ...state.participation.relationships, services: services } })
      }

      return true
    } catch (error) {
      console.error('API error (update participation service): ', error)
      return false
    }
  },

  async detachService({ commit, state, dispatch },  { participation, assigned }) {
    try {
      if (!state.isPreviewMode) {
        await $axios.delete(`/api/participations/${participation.id}/services/${assigned.id}`, {})

        dispatch('fetchParticipation', { id: participation.id })
      } else {
        let services = state.participation?.relationships?.services ?? []
        services = services.filter((el) => el.id !== assigned.id)

        commit(SET_PARTICIPATION, { ...state.participation, relationships: { ...state.participation.relationships, services: services } })
      }

      return true
    } catch (error) {
      console.error('API error (detach participation service): ', error)
      return false
    }
  },

  async fetchParticipationCost({ commit, dispatch }, { fair, participation }) {
    try {
      let cost = null

      if (!state.isPreviewMode) {
        cost = (await $axios.get(`/api/participations/${participation.id}/cost`)).data.data
      } else {
        cost = await dispatch('fetchParticipationCostPreview', { fair, participation })
      }

      commit(SET_PARTICIPATION_COST, cost)
      return true
    } catch (error) {
      console.error('API error (fetch participation cost): ', error)
      return false
    }
  },

  async fetchParticipationCostPreview({ state }, { fair, participation }) {
    let total = 0
    let lines = {}

    let type = participation?.attributes?.booking_type  ?? (fair.attributes.booking_type_space ? BOOKING_TYPES.SPACE : (fair.attributes.booking_type_space ? BOOKING_TYPES.PACKAGE : null));

    let packag = fair.relationships.packages.find((el) => el.id === participation.relationships?.package?.id);

    if (packag && type === BOOKING_TYPES.PACKAGE) {
      total += Number(packag[state.participationFormPricePreview])
      lines.package = {title: packag.attributes.title, amount:1, price: packag.attributes[state.participationFormPricePreview], total: packag.attributes[state.participationFormPricePreview]}
    }

    let spaces = []
    if (participation?.relationships?.spaces && type === BOOKING_TYPES.SPACE) {
      for (let space of participation?.relationships?.spaces) {
        total += Number(space.attributes.size * space.attributes[state.participationFormPricePreview])
        spaces.push({title: space.attributes.title, amount: space.attributes.size, price: space.attributes[state.participationFormPricePreview], total: space.attributes.size * space.attributes[state.participationFormPricePreview]})
      }
      lines.space = spaces
    }

    let fees = []
    for (let fee of fair?.relationships?.services.filter((el) => el.attributes.fee)) {
      total += 1 * fee.attributes.price
      fees.push({title: fee.attributes.title, amount: 1, price: fee.attributes.price, total: fee.attributes.amount * fee.attributes.price})
    }
    lines.fees = fees

    let services = []
    for (let service of participation?.relationships?.services ?? []) {
      let orig = fair.relationships.services.find((el) => service.attributes.tradefair_service_id === el.id)
      total += Number(service.attributes.amount * orig.attributes.price)
      services.push({title: orig.attributes.title, unit: orig.attributes.unit, amount: service.attributes.amount, price: orig.attributes.price, total: service.attributes.amount * orig.attributes.price})
    }
    lines.services = services

    let coexhibitors = participation?.relationships?.coexhibitors?.length
    if (coexhibitors && fair.attributes.coexhibitor_fee) {
      total += Number(coexhibitors * fair.attributes.coexhibitor_fee)
      lines.coExhibitorFee = {title: fair.attributes.coexhibitor_fee_title, amount: coexhibitors, price: fair.attributes.coexhibitor_fee, total: coexhibitors * fair.attributes.coexhibitor_fee}
    }

    if (coexhibitors && fair.attributes.coexhibitor_external_fee) {
      total += Number(coexhibitors * fair.attributes.coexhibitor_external_fee)
      lines.coExhibitorExternalFee = {title: fair.attributes.coexhibitor_external_fee_title, amount: coexhibitors, price: fair.attributes.coexhibitor_external_fee, total: coexhibitors * fair.attributes.coexhibitor_external_fee}
    }

    if (coexhibitors && fair.attributes.coexhibitor_external_fee2) {
      total += Number(coexhibitors * fair.attributes.coexhibitor_external_fee2)
      lines.coExhibitorExternalFee2 = {title: fair.attributes.coexhibitor_external_fee2_title, amount: coexhibitors, price: fair.attributes.coexhibitor_external_fee2, total: coexhibitors * fair.attributes.coexhibitor_external_fee2}
    }

    return {total, lines}
  },


  async checkValidationSteps({ commit }, { fair, participation }) {
    var steps = [];

    var areas = fair.relationships.exhibition_areas

    if (!participation?.attributes) {
      steps.push('ContactData')
    }

    if (fair.attributes.booking_type_space && fair.attributes.booking_type_package) {
      if (!participation?.attributes?.booking_type) {
        steps.push('BookingTypeSelect')
      }
    }

    if (participation?.attributes?.booking_type === BOOKING_TYPES.PACKAGE && !(participation?.relationships?.package && participation?.relationships?.package?.id)) {
      steps.push('ServicePackage')
    }

    if (participation?.attributes?.booking_type === BOOKING_TYPES.SPACE && !(participation?.relationships?.spaces?.length)) {
      steps.push('ExhibitionSpace')
    }

    if (areas.length && !(participation?.relationships?.area && participation?.relationships?.area.id)) {
      steps.push('ExhibitionArea')
    }

    if (participation?.attributes?.billing_address && participation?.attributes?.contact_email) {
      steps.push('BillingAddress')
    }

    if (!participation?.attributes?.declared_atb) {
      steps.push('TermsAndConditions')
    }

    if (steps.length) {
      steps.push('CheckAndSend')
    }

    commit(SET_PARTICIPATION_STEPS_ERRORED, steps)

    return !!steps.length;
  },


  /*******************************************************************************************/



  async fetchParticipations({commit}) {
    try {
      const participations = await api.get('/company/participations')
      commit(SET_PARTICIPATIONS, participations)

      return true
    } catch (error) {
      console.error('API error (fetch participations): ', error)
      return false
    }
  },

  async attachMandatoryServicesForPreview({rootGetters, dispatch}) {
    var tradeFair = rootGetters['organizer/currentTradeFair']

    var fees = tradeFair.services.filter((el) => { return !el.fee && el.mandatory; })
    for (var fee of fees) {
      dispatch('attachService', {tradeFairServiceId: fee.id, amount: 1})
    }
  },

  // eslint-disable-next-line no-unused-vars
  async clearParticipation({ commit }) {
    // commit(SET_PARTICIPATION_PACKAGE, defaultState().package)
    // commit(SET_PARTICIPATION_AREA, defaultState().area)
    // commit(SET_PARTICIPATION_GOODS, defaultState().goods)
    // commit(SET_PARTICIPATION_COEXHIBITORS, defaultState().coExhibitors)
    // commit(SET_PARTICIPATION_SPACE, defaultState().space)
    // commit(SET_PARTICIPATION_SERVICES, defaultState().services)
  },


  async saveBillingAddress({ commit, state }, payload) {
    try {
      if (!state.isPreviewMode) {
        const participation = await api.post(`/participations/${state.participation.id}/billing`, payload)

        commit(SET_PARTICIPATION, participation)
      } else {
        commit(SET_PARTICIPATION, payload)
      }


      return true
    } catch (error) {
      console.error('API error (save participation billing address): ', error)
      return false
    }
  },

  async fetchPreferredTradeFairs({commit}) {
    try {
      const { tradeFairs } = await api.get('/company-preferences')
      Object.values(tradeFairs).map(function (item) {
        if (item.isFavorite) {
          commit(SET_NOTED_TRADE_FAIR, item.id)
        }
      })

      commit(SET_PREFERRED_TRADE_FAIRS, tradeFairs)

      return true
    } catch (error) {
      console.error('API error (fetch preferred trade fairs): ', error)
      return false
    }
  },

  async setNotedTradeFair({commit, dispatch}, payload = {}) {
    commit(SET_NOTED_TRADE_FAIR, payload)
    const errorCallback = function () {
      commit(UNSET_NOTED_TRADE_FAIR, payload)
    }
    return await dispatch('postNotedTradeFairs', { callback: errorCallback })
  },

  async unsetNotedTradeFair({commit, dispatch}, payload = {}) {
    commit(UNSET_NOTED_TRADE_FAIR, payload)
    const errorCallback = function () {
      commit(SET_NOTED_TRADE_FAIR, payload)
    }
    return await dispatch('postNotedTradeFairs', { callback: errorCallback })
  },

  async postNotedTradeFairs({state, dispatch}, payload) {
    try {
      await api.post('/company-preferences', { tradeFairs: state.notedTradefairsIds }, { snakifyOutgoing: false })
      await dispatch('fetchPreferredTradeFairs')
      await dispatch('tradefair/setNotedTradeFairs', state.notedTradefairsIds, {root: true})
      return true
    } catch (error) {
      console.error('API error (post noted trade fairs): ', error)
      payload.callback()

      return false
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async downloadRegistrationConfirmation({}, {participation, tradeFair}) {
    try {
      if (tradeFair) {
        let filename = `${tradeFair.name}_${tradeFair.id}_${participation.submittedAt}`

        await api.post(`participations/${participation.id}/confirmation?all=0`,
            {},
            {file: {name: filename, type: 'pdf'}},
            {responseType: 'blob'}
        )
      } else {
        await api.post(`participations/${participation.id}/confirmation?all=1`,
            {},
            {file: {name: 'Registration confirmations', type: 'zip'}},
            {responseType: 'blob'}
        )
      }

      return true
    } catch (error) {
      console.error('API error (fetch accreditations): ', error)
      return false
    }
  }
}

const mutations = {
  [SET_PARTICIPATIONS] (state, participations) {
    state.participations = participations
  },

  [SET_PARTICIPATION] (state, participation) {
    state.participation = participation
  },

  [SET_PARTICIPATION_COST] (state, cost) {
    state.cost = cost
  },

  [SET_PARTICIPATION_PREVIEW_MODE] (state, mode) {
    state.isPreviewMode = mode
  },

  [SET_PARTICIPATION_PREVIEW_LANGUAGE] (state, language) {
    state.participationFormLanguagePreview = language
  },

  [SET_PARTICIPATION_PREVIEW_PRICE] (state, price) {
    state.participationFormPricePreview = price
  },

  [SET_PREFERRED_TRADE_FAIRS] (state, tradeFairs) {
    state.preferredTradeFairs = tradeFairs
  },

  [SET_NOTED_TRADE_FAIR] (state, id) {
    const tradeFairIndex = state.notedTradefairsIds.indexOf(id)
    if (tradeFairIndex === -1) {
      state.notedTradefairsIds.push(id)
    }
  },

  [UNSET_NOTED_TRADE_FAIR] (state, id) {
    const tradeFairIndex = state.notedTradefairsIds.indexOf(id)
    if (tradeFairIndex !== -1) {
      state.notedTradefairsIds.splice(tradeFairIndex, 1)
    }
  },

  [SET_PARTICIPATION_STEPS_ERRORED](state, steps) {
    state.stepsError = steps
  },
}

const properties = Object.keys(defaultState())
const defaultGetters = makeDefaultGetters(properties)

const getters = {
  ...defaultGetters,

  allParticipations: state => state.participations,

  notedTradeFairs: state => Object.values(state.preferredTradeFairs).filter(tradeFair => tradeFair.isFavorite),

  preferredTradeFairs: state => Object.values(state.preferredTradeFairs),

  participationExists: state => !!state.participation.id,
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
}
