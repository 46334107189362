<template>
  <div>
    <v-stepper-step
      :step="step"
      :editable="isEditable"
      :rules="[() => !isStepsErrored]"
    >
      <span
        class="stepper-abstract"
        v-if="participation?.attributes?.contact_firstname"
      >
        {{ info?.attributes?.company ? info.attributes.company + ',' : '' }}
        {{ $t('participation.contactData.contactPerson', lang) }}:
        {{ participation.attributes.contact_firstname }}
        {{ participation.attributes.contact_lastname }}
      </span>
      <h3 :class="!isStepsErrored ? 'primary--text' : ''">
        {{ $t('participation.contactData.title', lang) }}
      </h3>
      <small v-if="isStepsErrored">
        {{ $t('participation.stepsIsErrored', lang) }}
      </small>
    </v-stepper-step>

    <v-stepper-content :step="step" class="pb-10">
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="py-0">
            <p
              class="mb-0"
              v-html="$t('accreditation.steps.publicParticipation.hintTitle')"
            ></p>
            <p
              class="red--text mb-5 pb-5"
              v-html="
                $t('accreditation.steps.publicParticipation.hintTitleRed')
              "
            ></p>

            <div>
              {{
                $t(
                  'participation.financialSupport.declaration.list.notReceiveFurtherPublicFunds',
                  lang
                )
              }}
              <v-col cols="4" class="px-0 py-0">
                <v-slider
                  v-model="form.data.declared_sponsored"
                  :readonly="isReadonlyMode"
                  :tick-labels="declared_sponsored_ranges"
                  min="-1"
                  max="1"
                  ticks="always"
                  tick-size="3"
                  :error-messages="errors.declared_sponsored"
                >
                </v-slider>
              </v-col>
            </div>

            <v-divider class="my-4" />

            <div>
              {{
                $t(
                  'participation.contactData.participatedLessThanFiveTimes',
                  lang
                )
              }}
              <v-col cols="4" class="px-0 py-0">
                <v-slider
                  v-model="form.data.declared_less_then_five_participations"
                  :readonly="isReadonlyMode"
                  :tick-labels="declared_less_then_five_participations_ranges"
                  min="-1"
                  max="1"
                  ticks="always"
                  tick-size="3"
                  :error-messages="
                    errors.declared_less_then_five_participations
                  "
                >
                </v-slider>
              </v-col>
            </div>

            <v-divider class="my-4" />

            <v-checkbox
              v-model="form.data.incorrectInformationAwareness"
              :error-messages="errors.incorrectInformationAwareness"
              :readonly="isReadonlyMode"
            >
              <template v-slot:label>
                <span style="color: black">
                  {{
                    $t(
                      'participation.contactData.incorrectInformationAwareness',
                      lang
                    )
                  }}
                </span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>

        <v-divider class="my-5" />

        <v-row>
          <v-col cols="6" class="pr-10">
            <h3 class="mb-3">
              {{ $t('participation.contactData.contactPerson', lang) }}
            </h3>
            <p>
              {{ $t('participation.contactPersonHint', lang) }}
            </p>

            <exhibitor-contacts
              :participationContacts="
                participation?.relationships?.contacts ?? []
              "
              :fair="fair"
              :participation="participation"
              @countContacts="retrieveContactsCount"
              :isPreviewMode="isPreviewMode"
              ref="exhibitorContacts"
            ></exhibitor-contacts>
            <p class="error--text mt-4">
              {{ contactsErrorMsg }}
            </p>
          </v-col>
          <v-col cols="6" v-if="info?.relationships.country">
            <v-row>
              <v-col cols="12">
                <strong>
                  {{ $t('participation.contactData.companyData', lang) }}
                  <HelpButton :icon-size="16" :help-entry-id="2" />
                </strong>
              </v-col>
              <v-col cols="6">
                <p>
                  {{ info.attributes.company }}
                  <br />
                  {{ info.attributes.street }}
                  <br />
                  <span v-if="info.attributes.address_supplement"
                    >{{ info.attributes.address_supplement }}<br
                  /></span>
                  {{ info.attributes.postalcode }} {{ info.attributes.city }}
                  <br />
                  <span v-if="info.relationships?.federal_state?.id"
                    >{{
                      info.relationships.federal_state?.attributes.name[
                        $i18n.locale
                      ]
                    }}<br
                  /></span>
                  {{
                    info.relationships?.country?.translations[$i18n.locale]
                      .country
                  }}
                </p>

                <small v-if="info.attributes.vat_id">
                  {{ $t('forms.fields.vatID', lang) }}
                </small>
                <p v-if="info.attributes.vat_id">
                  {{ info.attributes.vat_id }}
                </p>
              </v-col>

              <v-col cols="6">
                <small>
                  {{ $t('forms.fields.phone', lang) }}
                </small>
                <p>{{ info.attributes.phone }}</p>

                <template v-if="info.attributes.fax">
                  <small>
                    {{ $t('forms.fields.fax', lang) }}
                  </small>
                  <p>{{ info.attributes.fax }}</p>
                </template>

                <small>
                  {{ $t('forms.fields.email', lang) }}
                </small>
                <p>
                  <a :href="'mailto:' + info.attributes.email">{{
                    info.attributes.email
                  }}</a>
                </p>

                <small>
                  {{ $t('forms.fields.website', lang) }}
                </small>
                <p>
                  <a :href="info.attributes.website" target="_blank">{{
                    info.attributes.website
                  }}</a>
                </p>
              </v-col>

              <v-col
                cols="12"
                v-if="fair.attributes.servicedescription_general[lang]?.length"
              >
                <strong>
                  {{
                    $t(
                      'participation.configureRegistrationForm.serviceDescription.generalServiceDescription',
                      lang
                    )
                  }}
                </strong>
                <div
                  v-html="fair.attributes.servicedescription_general[lang]"
                ></div>
              </v-col>
            </v-row>
          </v-col>

          <v-row class="mt-5">
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="6">
              <v-form class="mt-5">
                <div class="d-flex">
                  <v-checkbox
                    :readonly="isReadonlyMode"
                    class="mr-4"
                    :label="$t('forms.fields.billPerEMail')"
                    v-model="form.data.bill_by_email"
                  ></v-checkbox>
                  <v-text-field
                    v-if="form.data.bill_by_email"
                    v-model="form.data.billingaddress_email"
                    :label="$t('forms.fields.billingEMail')"
                    :error-messages="errors.billingaddress_email"
                    :readonly="isReadonlyMode || !form.data.bill_by_email"
                    class="rounded-0"
                    filled
                    dense
                  />
                </div>

                <div class="d-flex">
                  <v-checkbox
                    :readonly="isReadonlyMode"
                    class="mr-4"
                    :label="$t('forms.fields.brandFlag')"
                    v-model="form.data.brand_flag"
                  ></v-checkbox>
                  <v-text-field
                    v-if="form.data.brand_flag"
                    v-model="form.data.brand"
                    :label="$t('forms.fields.brandPlaceholder')"
                    :error-messages="errors.brand"
                    :readonly="isReadonlyMode || !form.data.brand_flag"
                    maxlength="250"
                    class="rounded-0"
                    filled
                    dense
                  />
                </div>
              </v-form>
            </v-col>
          </v-row>

          <v-col cols="12" class="text-right">
            <v-btn
              class="elevation-0 secondary"
              :loading="form.loading"
              :disabled="!isSubmittedForm || isPreviewMode"
              @click="handleSubmit"
            >
              {{ $t('forms.buttons.saveAndContinue', lang) }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-stepper-content>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { email, sameAs, requiredIf, maxLength } from 'vuelidate/lib/validators'
import { GENDERS } from '@/enums/genders'
import validation from '@/mixins/validate'
import participationStep from '@/mixins/participationStep'
import participationMode from '@/mixins/participationMode'
import ExhibitorContacts from '@/components/common/Exhibitor/Contacts'

export default {
  name: 'ContactData',

  components: {
    ExhibitorContacts,
  },

  props: {
    fair: {
      type: Object,
    },

    company: {
      type: Object,
    },

    participation: {
      type: Object,
    },

    step: {
      type: Number,
      default: 1,
    },

    mode: {
      type: String,
    },

    isEditable: {
      type: Boolean,
    },
  },

  mixins: [validation, participationStep, participationMode],

  data() {
    return {
      countContacts: null,
      contactsErrorMsg: null,
      form: {
        loading: false,

        customFieldsMap: {
          title: 'contact_title',
          first_name: 'contact_firstname',
          last_name: 'contact_lastname',
          gender: 'contact_gender',
          phone: 'contact_phone',
          mobile: 'contact_mobile',
          fax: 'contact_fax',
          email: 'contact_email',
          billingaddress_email: 'billingaddress_email',
          brand: 'brand',
        },

        data: {
          title: null,
          first_name: null,
          last_name: null,
          gender: null,
          phone: null,
          mobile: null,
          fax: null,
          email: null,
          declared_sponsored: null,
          declared_less_then_five_participations: null,
          bill_by_email: true,
          billingaddress_email: null,
          brand_flag: false,
          brand: null,
          incorrectInformationAwareness: false,
        },

        validationRules: {
          title: {},
          first_name: {},
          last_name: {},
          gender: {},
          phone: {},
          mobile: {},
          fax: {},
          email: {},
          bill_by_email: {},
          billingaddress_email: {
            requiredIf: requiredIf(function () {
              return this.form.data.bill_by_email
            }),
            email,
          },
          brand_flag: {},
          brand: {
            maxLength: maxLength(250),
            requiredIf: requiredIf(function () {
              return this.form.data.brand_flag
            }),
          },
          declared_sponsored: {},
          declared_less_then_five_participations: {},
          incorrectInformationAwareness: {
            sameAs: sameAs(() => true),
          },
        },

        custom: {
          validationRulesMap: {
            incorrectInformationAwareness: {
              sameAs: 'checkboxRequired',
            },
          },
          validationSubstitutions: {
            billingaddress_email: {
              requiredIf: {
                field: this.$t('forms.fields.email'),
              },
            },
            phone: {
              minLength: {
                length: 6,
              },
            },
            fax: {
              minLength: {
                length: 6,
              },
            },
            brand: {
              maxLength: {
                length: 250,
              },
            },
          },
        },

        isValid: true,
      },

      preview: {
        attributes: {
          company: 'Beispiel GmbH',
          postalcode: '12345',
          city: 'Beispielstadt',
          vatId: 'DE123456799',
          phone: '+49 1234 56789',
          fax: '+49 1234 56789',
          email: 'info@beispiel-gmbh.de',
          website: 'www.beispiel-gmbh.de',
        },
        relationships: {
          country: {
            translations: {
              en: { country: 'Germany' },
              de: { country: 'Deutschland' },
            },
          },
          federal_state: {
            attributes: { name: { en: 'Bavaria', de: 'Bayern' } },
          },
        },
        included: {},
      },
    }
  },

  computed: {
    declared_sponsored_ranges() {
      return [
        this.$t('common.range.false'),
        this.$t('common.range.select'),
        this.$t('common.range.true'),
      ]
    },

    declared_less_then_five_participations_ranges() {
      return [
        this.$t('common.no'),
        this.$t('common.range.select'),
        this.$t('common.yes'),
      ]
    },

    genders() {
      return Object.values(GENDERS).map((gender) => ({
        type: gender,
        label: this.$t(`common.genders.${gender}`, this.lang),
      }))
    },

    info() {
      if (!this.isPreviewMode) {
        return this.company
      }

      return this.preview
    },
  },

  created() {
    this.setInitialFormData(
      this.participation?.attributes ?? { bill_by_email: true }
    )
    this.form.data.brand_flag = !!this.participation?.attributes?.brand

    if (this.participation?.id) {
      this.form.data.incorrectInformationAwareness = true
    }

    let maps = { 0: -1, null: 0, 1: 1, false: -1, true: 1 }
    this.form.data.declared_sponsored =
      maps[this.participation?.attributes?.declared_sponsored ?? null]
    this.form.data.declared_less_then_five_participations =
      maps[
        this.participation?.attributes
          ?.declared_less_then_five_participations ?? null
      ]
  },

  methods: {
    ...mapActions('participation', {
      updateParticipation: 'updateParticipation',
      createParticipation: 'createParticipation',
    }),

    async handleSubmit() {
      this.contactsErrorMsg = null
      this.resetServerErrors()

      if (
        !this.form.data.declared_less_then_five_participations ||
        !this.form.data.declared_sponsored
      ) {
        let errors = {}

        if (!this.form.data.declared_sponsored)
          errors.declared_sponsored = [this.$t('validation.mandatory')]
        if (!this.form.data.declared_less_then_five_participations)
          errors.declared_less_then_five_participations = [
            this.$t('validation.mandatory'),
          ]

        this.setServerErrors(errors)
      }

      if (
        !this.checkIfFormFieldsAreValid() ||
        !this.form.data.declared_less_then_five_participations ||
        !this.form.data.declared_sponsored
      ) {
        this.form.isValid = false
        this.$emit('open', 'ContactData')
        return
      }

      const response = await this.$refs.exhibitorContacts.applyChanges()

      if (!response.success) {
        return
      }

      let maps = { 0: null, '-1': false, 1: true }
      let data = {
        ...this.getFormData(),
        declared_sponsored: maps[this.form.data.declared_sponsored],
        declared_less_then_five_participations:
          maps[this.form.data.declared_less_then_five_participations],
      }
      if (this.participation?.id) {
        if (
          await this.updateParticipation({
            fair: this.fair,
            participation: this.participation,
            data,
          })
        ) {
          this.form.isValid = true
          this.$emit('submit')
        }
      }
    },

    retrieveContactsCount(event) {
      this.countContacts = event
      this.contactsErrorMsg = null
    },
  },

  watch: {
    'form.data.bill_by_email'(val) {
      if (!val) {
        this.form.data.billingaddress_email = null
      }
    },
  },

  validations() {
    return {
      form: {
        data: {
          ...this.form.validationRules,
        },
      },
    }
  },
}
</script>
