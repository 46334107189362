export default {
  validation: {
    required: '{field} erforderlich',
    mandatory: 'Diese Angabe ist erforderlich',
    translation: '{field} erforderlich',
    requiredIf: '{field} erforderlich',
    email: '{field} muss eine gültige E-Mail sein',
    minLength: '{field} muss aus mindestens {length} Zeichen bestehen',
    maxLength: '{field} darf höchstens {length} Zeichen enthalten',
    sameAs: '{field} muss mit dem {sameAsField} Feld identisch sein',
    sameAsPassword: '{field} muss mit dem {sameAsField} Feld identisch sein',
    checkboxRequired: '{value} muss akzeptiert werden',
    url: '{field} muss eine gültige URL sein',
    minValue: '{field} darf nicht kleiner sein als {limit}',
    maxValue: '{field} darf nicht größer sein als {limit}',
    participationIsNotCreated:
      'Die Teilnahme ist noch nicht angelegt. Bitte füllen Sie zuerst das Ausstellerformular aus',
    activateCheckboxesToProceed: 'Bitte bestätigen Sie die Aussagen',
    integer: '{field} muss eine positive ganze Zahl sein',
    minFileSize: ' Die Datei darf höchstens {limit} klein sein',
    maxFileSize: ' Die Datei darf höchstens {limit} groß sein',
    contactPersonRequired: {
      contactPersonMin:
        'Es muss mindestens ein Ansprechpartner für "Organisation und Messevorbereitung" vorhanden sein.',
      contactPersonMax:
        'Es dürfen maximal zwei Ansprechpartner für "Organisation und Messevorbereitung" vorhanden sein.',
      onSiteMax:
        'Es dürfen maximal zehn "Auf der Messe"-Kontaktpersonen vorhanden sein.',
      mediaMin:
        'Es muss mindestens ein Ansprechpartner für "Medieneintrag" vorhanden sein.',
      mediaMax:
        'Es dürfen maximal fünf Ansprechpartner für "Medieneintrag" vorhanden sein.',
    },
    contactRequired: 'Es muss mindestens ein Ansprechpartner vorhanden sein.',
    contactTypeRequired: 'Es muss eine Option ausgewählt werden.',
  },
}
