<template>
  <div v-if="participation?.id && fair?.id">
    <v-app-bar class="elevation-0" color="white">
      <v-btn
          class="ml-1"
          icon
          @click="hasHistory() ? $router.go(-1) : $router.push('/')"
      >
        <v-icon color="secondary">fal fa-angle-left</v-icon>
      </v-btn>
      <v-toolbar-title>
        <h5 v-if="form.loading">
          {{ $t('participation.registrations') }}
          <i class="fal fa-angle-right mx-3"></i>
          {{ participation.relationships.company?.attributes.company }}
        </h5>
      </v-toolbar-title>
    </v-app-bar>

    <v-divider/>

    <v-row>
      <v-col cols="3" class="px-0">
        <v-timeline
            v-if="!form.loading"
            class="elevation-0"
            dense
            align-top
        >
          <v-timeline-item
              v-if="participation.included.was_interested"
              color="secondary"
              small
          >
            <small>{{ participation.included.was_interested | dateTimeByLocale }}</small>
            <p>{{ $t('participation.expressionOfInterest') }}</p>
          </v-timeline-item>

          <v-timeline-item
              v-if="participation.attributes.submitted_at"
              color="secondary"
              small
          >
            <small>{{ participation.attributes.submitted_at | dateTimeByLocale }}</small>
            <p>{{ $t('participation.signUp') }}</p>
          </v-timeline-item>

          <v-timeline-item
              v-if="participation.included.is_approved"
              color="secondary"
              small
          >
            <small>{{ participation.attributes.approved_at | dateTimeByLocale }}</small>
            <!--            TODO: -->
            <p>
              {{
                $t('participation.actionsByProjectManager.confirmed', {
                  manager: (participation.relationships.approved_by_user.attributes.first_name) + ' ' + (participation.relationships.approved_by_user.attributes.last_name),
                })
              }}
            </p>
          </v-timeline-item>

          <v-timeline-item
              v-if="participation.included.is_cancelled"
              color="secondary"
              small
          >
            <small>{{ participation.attributes.cancelled_at | dateTimeByLocale }}</small>
            <p>
              {{
                $t('participation.actionsByProjectManager.cancelled', {
                  manager: participation.relationships.cancelled_by_user.attributes.first_name + ' ' + (participation.relationships.cancelled_by_user.attributes.last_name),
                  role: participation.relationships.cancelled_by_user.attributes.organizer_id
                      ? $t('participation.projectManager')
                      : $t('participation.company'),
                })
              }}
            </p>
          </v-timeline-item>

          <v-timeline-item
              v-if="participation.attributes.is_rejected"
              color="secondary"
              small
          >
            <small>{{ participation.attributes.rejected_at | dateTimeByLocale }}</small>
            <p>
              {{
                $t('participation.actionsByProjectManager.rejected', {
                  manager: participation.relationships.rejected_by_user.attributes.first_name + ' ' + (participation.relationships.rejected_by_user.attributes.last_name),
                })
              }}
            </p>
          </v-timeline-item>
        </v-timeline>
      </v-col>

      <v-col cols="9" class="pa-0">
        <v-container class="pa-9" fluid>
          <v-card class="elevation-1 rounded-0">
            <v-toolbar flat>
              <v-toolbar-title>
                <h4
                    v-if="!form.loading"
                    class="primary--text"
                >
                  {{ $t('participation.signUp') }}
                  {{ participation.relationships.company.company }}
                </h4>
              </v-toolbar-title>

              <v-spacer/>
              <v-menu open-on-click bottom left offset-x>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" icon v-on="on">
                    <v-icon>fal fa-ellipsis-v</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                      v-if="isAvailableToReprocess(participation)"
                      @click="handleReprocessParticipation(participation)"
                  >
                    <v-list-item-title>
                      {{ $t('participation.actions.returnForProcessing') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                      v-if="isAvailableToApprove(participation)"
                      @click="handleApproveParticipation(participation)"
                  >
                    <v-list-item-title>
                      {{ $t('participation.actions.confirm') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                      v-if="isAvailableToCancel(participation)"
                      @click="handleCancelParticipation(participation)"
                  >
                    <v-list-item-title>
                      {{ $t('participation.actions.cancel') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                      v-if="isAvailableToReject(participation)"
                      @click="handleRejectParticipation(participation)"
                  >
                    <v-list-item-title>
                      {{ $t('participation.actions.reject') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item
                      v-if="isAvailableToWaiting(participation)"
                      @click="handleWaitingParticipation(participation)"
                  >
                    <v-list-item-title>
                      {{ $t('participation.actions.waiting') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>

            <v-divider/>

            <v-card-text class="text--primary">
              <v-row>
                <v-col cols="12" class="text-right">
                  <v-chip
                      v-if="!form.loading"
                      :color="colorStatus(participation)"
                      pill
                      small
                  >
                    {{
                      $t(
                          `tables.participation.pageStatus.${participation.attributes.status}`
                      )
                    }}
                  </v-chip>
                </v-col>

                <v-col cols="12" class="py-0">
                  <small>{{
                      $t('participation.notificationOfTheParticipant')
                    }}</small>
                  <p>{{ participation.attributes.message }}</p>
                </v-col>

                <v-col cols="3" v-if="!form.loading">
                  <small>{{
                      $t('participation.addressAndContactDetails')
                    }}</small>
                  <p>
                    {{ participation.relationships.company.attributes.company }}<br/>
                    {{ participation.relationships.company.attributes.street }}<br/>
                    <span v-if="participation.relationships.company.attributes.address_supplement">{{
                        participation.relationships.company.attributes.address_supplement
                      }}<br/></span>
                    {{ participation.relationships.company.attributes.postalcode }}
                    {{ participation.relationships.company.attributes.city }}
                  </p>
                  <p v-if="participation.relationships.company.attributes.vat_id || participation.attributes.billingaddress_email">
                    <span v-if="participation.attributes.billingaddress_email">
                      {{ $t('participation.billingaddressEmail') }}: {{ participation.attributes.billingaddress_email }}
                      <br/>
                    </span>
                    <span v-if="participation.relationships.company.attributes.vat_Id">
                      {{ $t('forms.fields.vatID') }}: {{ participation.relationships.company.attributes.vat_id }}
                      <br/>
                    </span>
                  </p>

                  <p
                      v-if="
                      participation.relationships.company.attributes.is_foreign_branch ||
                      participation.relationships.company.attributes.is_foreign_representation
                    "
                  >
                    <v-icon class="primary--text mb-1 mr-3" size="14">
                      fal
                      {{
                        participation.relationships.company.attributes.is_foreign_branch
                            ? 'fa-check'
                            : 'fa-times'
                      }}
                      fa-fw
                    </v-icon>
                    <span>{{ $t('tables.accreditation.foreignBranch') }}</span
                    ><br/>
                    <v-icon class="primary--text mb-1 mr-3" size="14">
                      fal
                      {{
                        participation.relationships.company.attributes.is_foreign_representation
                            ? 'fa-check'
                            : 'fa-times'
                      }}
                      fa-fw
                    </v-icon>
                    <span>{{
                        $t('tables.accreditation.foreignRepresentation')
                      }}</span>
                    <br/><br/>
                    <small>{{
                        $t('tables.accreditation.connectedCompany') + ': '
                      }}</small
                    ><br/>
                    <span> {{ participation.relationships.company.attributes.related_company }} </span>
                  </p>

                  <p>
                    {{ participation.relationships.company.attributes.email }}<br/>
                    <a :href="participation.relationships.company.attributes.website">
                      {{
                        participation.relationships.company.attributes.website
                      }}
                    </a>
                  </p>

                  <p>
                    {{ $t('participation.phone') }}:
                    {{ participation.relationships.company.attributes.phone }}
                  </p>
                  <v-list
                      v-if="participation.attributes.submitted_at"
                      flat
                      dense
                      color="transparent"
                  >
                    <v-list-item class="px-0"
                                 v-if="participation.attributes.status != PARTICIPATION_STATUS.REPROCESSED">
                      <v-list-item-content>
                        <v-list-item-title>
                          <a href="#"
                             @click.prevent="downloadRegistrationConfirmation({ participation: participation, tradeFair: participation.relationships.fair })">
                            {{
                              $t(
                                  'tables.participation.form.downloadRegistrationConfirmation'
                              )
                            }}
                          </a>
                          <br/>
                          ({{ participation.attributes.approved_at | dateByLocale }})
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-col>

                <v-col cols="4" class="py-0">
                  <table class="facts">
                    <tbody>
                    <tr
                        v-if="participation.relationships.spaces && participation.relationships.length"
                    >
                      <td class="pl-0">
                        <small>
                          {{
                            $t('participation.bookedExhibitionSpace')
                          }}
                        </small>
                      </td>
                      <td class="pl-0">
                          <span>
                            {{ participation.relationships.space.reduce((acc, el) => acc + el.attributes.size_assigned, 0) }} m<sup>2</sup>
                            {{
                              participation.relationships.spaces[0].attributes.construction_included
                                  ? $t(
                                      'participation.exhibitionSpace.withStandConstruction'
                                  )
                                  : $t(
                                      'participation.exhibitionSpace.withoutStandConstruction'
                                  )
                            }}
                          </span>
                      </td>
                    </tr>
                    <tr
                        v-if="participation.relationships.area"
                    >
                      <td class="pl-0">
                        <small>
                          {{
                            $t('participation.exhibitionArea.title')
                          }}
                        </small>
                      </td>
                      <td class="pl-0">
                          <span>
                            {{ participation.relationships.area.attributes.name[$i18n.locale] }}<br/>
                          </span>
                      </td>
                    </tr>

                    <tr
                        v-if="participation.relationships.goods && participation.relationships.goods.length"
                    >
                      <td class="pl-0">
                        <small>
                          {{
                            $t('participation.exhibitionGoods.title')
                          }}
                        </small>
                      </td>
                      <td class="align-start">
                          <span
                              v-for="good in participation.relationships.goods"
                              :key="good.id"
                          >
                            {{ good.attributes.title }}<br/>
                          </span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <application-form
              v-if="fair?.id"
              :fair="fair"
              :mode="REGISTRATION_MODES.READONLY"
              exclude-header
          />
        </v-container>
      </v-col>
    </v-row>
    <return-participant-message-modal ref="return"></return-participant-message-modal>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import ApplicationForm from '@/components/ApplicationForm'
import {REGISTRATION_MODES} from '@/enums/registrationModes'
import {PARTICIPATION_STATUS} from '@/enums/participationStatus'
import ReturnParticipantMessageModal from '@/components/organizer/Participants/Modals/ReturnParticipantMessageModal';

export default {
  name: 'OrganizerParticipants',

  components: {
    ReturnParticipantMessageModal,
    ApplicationForm
  },

  data() {
    return {
      form: {
        loading: false,
      },

      fair: null,

      REGISTRATION_MODES,
      PARTICIPATION_STATUS,
    }
  },

  computed: {
    ...mapGetters('participation', [
      'participation',
    ]),
  },

  async created() {
    if (await this.fetchParticipation({id: this.$route.params.id})) {
      await this.setCurrentTradeFair(this.participation.attributes.tradefair_id)

      this.load()
    } else {
      // await this.$router.push({ name: 'organizer.dashboard' })
    }
  },

  methods: {
    hasHistory() {
      return window.history.length > 2
    },

    ...mapActions('participation', [
      'fetchParticipation',
      'reprocessParticipation',
      'approveParticipation',
      'cancelParticipation',
      'rejectParticipation',
      'waitingParticipation',
      'downloadRegistrationConfirmation',
    ]),

    ...mapActions('organizer', ['setCurrentTradeFair', 'fetchCurrentTradeFair']),

    ...mapActions('tradefair', ['fetchTradeFair']),

    async load() {
      this.form.loading = true

      let response = (await this.$axios.get(`/api/trade-fairs/${this.participation.attributes.tradefair_id}`, {
        params: {
          'languages': '*',
          'include': [
            'accessUsers',
            'associations',
            'country',
            'exhibitionAreas',
            'industrySectors',
            'organizer',
            'packages',
            'services',
            'spaces',
            'type',
            'interestedCompanies',
          ],
        },
      })).data

      this.fair = response.data

      this.form.loading = false
    },

    isAvailableToApprove(participation) {
      return [
        PARTICIPATION_STATUS.SUBMITTED,
        PARTICIPATION_STATUS.CANCELLED,
        PARTICIPATION_STATUS.REJECTED,
        PARTICIPATION_STATUS.WAITING,
      ].includes(participation.attributes.status)
    },

    isAvailableToReprocess(participation) {
      return [
        PARTICIPATION_STATUS.SUBMITTED,
        PARTICIPATION_STATUS.APPROVED,
        PARTICIPATION_STATUS.CANCELLED,
        PARTICIPATION_STATUS.REJECTED,
        PARTICIPATION_STATUS.WAITING,
      ].includes(participation.attributes.status)
    },

    isAvailableToCancel(participation) {
      return [
        PARTICIPATION_STATUS.SUBMITTED,
        PARTICIPATION_STATUS.APPROVED,
        PARTICIPATION_STATUS.WAITING,
      ].includes(participation.attributes.status)
    },

    isAvailableToReject(participation) {
      return [
        PARTICIPATION_STATUS.SUBMITTED,
        PARTICIPATION_STATUS.APPROVED,
        PARTICIPATION_STATUS.WAITING,
      ].includes(participation.attributes.status)
    },

    isAvailableToWaiting(participation) {
      return [
        PARTICIPATION_STATUS.SUBMITTED,
        PARTICIPATION_STATUS.APPROVED,
        PARTICIPATION_STATUS.CANCELLED,
        PARTICIPATION_STATUS.REJECTED,
      ].includes(participation.attributes.status)
    },

    colorStatus(participation) {
      switch (participation.attributes.status) {
        case PARTICIPATION_STATUS.SUBMITTED:
          return 'warning'
        case PARTICIPATION_STATUS.APPROVED:
          return 'success'
        case PARTICIPATION_STATUS.CANCELLED:
          return 'gray'
        case PARTICIPATION_STATUS.REJECTED:
          return 'gray'
        case PARTICIPATION_STATUS.REPROCESSED:
          return 'primary'
        case PARTICIPATION_STATUS.WAITING:
          return 'light-green'
        default:
          return 'gray'
      }
    },

    async handleReprocessParticipation(participation) {
      this.$refs.return.show(async (deniedNote) => {
        await this.reprocessParticipation({id: participation.id, deniedNote})

        this.$router.push('/organizer/participants/overview')
      })
    },

    async handleApproveParticipation(participation) {
      await this.approveParticipation(participation.id)

      await this.fetchParticipation({id: this.$route.params.id})
    },

    async handleCancelParticipation(participation) {
      this.$refs.return.show(async (deniedNote) => {
        await this.cancelParticipation({id: participation.id, deniedNote})

        await this.fetchParticipation({id: this.$route.params.id})
      })
    },

    async handleRejectParticipation(participation) {
      this.$refs.return.show(async (deniedNote) => {
        await this.rejectParticipation({id: participation.id, deniedNote})

        await this.fetchParticipation({id: this.$route.params.id})
      })
    },

    async handleWaitingParticipation(participation) {
      await this.waitingParticipation({id: participation.id})

      this.load()
    },
  },
}
</script>

